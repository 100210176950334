import { useState, useMemo } from 'react';
import { INSPECTOR_PAGE_SIZE } from './constants/pagination';

export function useMappingPagination(allMappings, updateMappings, isPaginationEnabled = false) {
  const [page, setPage] = useState(1);

  const mappings = useMemo(() => {
    if (!isPaginationEnabled) {
      return allMappings;
    }

    const start = (page - 1) * INSPECTOR_PAGE_SIZE;
    const end = page * INSPECTOR_PAGE_SIZE;

    return allMappings.slice(start, end);
  }, [allMappings, page, isPaginationEnabled]);

  const onAddMapping = mapping => {
    const total = allMappings.length;
    const pages = Math.ceil(total / INSPECTOR_PAGE_SIZE);

    updateMappings({
      mappings: [...allMappings, mapping],
    });

    if (total / INSPECTOR_PAGE_SIZE < pages) {
      setPage(pages);
    } else {
      setPage(pages + 1);
    }
  };

  const onDeleteMapping = mapping => {
    const total = allMappings.length;
    const pages = Math.ceil(total / INSPECTOR_PAGE_SIZE);
    const afterTotal = total - 1;
    const afterPages = Math.ceil(afterTotal / INSPECTOR_PAGE_SIZE);

    if (afterTotal === 0) {
      updateMappings({
        mappings: [{ left: null, right: null }],
      });
    } else {
      updateMappings({
        mappings: allMappings.filter(m => m !== mapping),
      });

      if (page === pages && afterPages < pages) {
        setPage(prevPage => prevPage - 1);
      }
    }
  };

  const onFieldChange = (i, side, value) => {
    const index = (page - 1) * INSPECTOR_PAGE_SIZE + i;

    updateMappings({
      mappings: allMappings.map((map, j) => {
        if (j !== index) {
          return map;
        }

        return {
          ...map,
          [side]: value,
        };
      }),
    });
  };

  return {
    page,
    setPage,
    total: allMappings.length,
    mappings,
    paginationProps: isPaginationEnabled
      ? {
          allMappings,
          onAddMapping,
          onDeleteMapping,
          onFieldChange,
        }
      : {},
  };
}
