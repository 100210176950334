import * as wkpFetch from '../../_shared/fetch';
import { workpapersNodeServiceHostName, spreadsheetServiceHostName } from '../../../configs/params';
import { generalErrorMessage } from '../../_shared/messages';
import { getDataFromLocalStorage } from '../../_shared/storage';
import { getKeyData } from './Spreadsheet/_spreadsheets/apis';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { CELL_REVIEW_MIGRATION, SJS_API } from '../../../constants/featureFlags';
import { processPendingCommandsWithSJS } from './EditorContext/useCommandsQueue/apis';
import CustomLogger from '../../_shared/Logger/CustomLogger';

export const WORKPAPER_NOT_FOUND_ERROR = 'WORKPAPER_NOT_FOUND_ERROR';

let staticGenerationError;
export async function getWorkbook({ id, versionId, statusRetryRef }) {
  if (versionId) {
    try {
      return await fetchPresignedUrl(await getWorkbookModel(id, versionId));
    } catch (error) {
      throwStaticVersionError(error);
    }
  } else {
    if (isFeatureFlagEnabled(CELL_REVIEW_MIGRATION)) {
      const { creatingWorkpaper } = JSON.parse(getDataFromLocalStorage(id) || '{}');
      if (!creatingWorkpaper) {
        const keyValuePairs = await getKeyData({ workpaperId: id });
        const hasCellReviewMigrationRan = keyValuePairs.find(pair => pair.key === 'cellReviewMigrated')?.value;
        if (hasCellReviewMigrationRan !== 'true') {
          try {
            await migrateCellReviews(id);
          } catch (error) {
            if (!(error?.response && error?.response?.status === 504)) {
              console.log('Error migrating cell reviews:', error);
            }
          }
        }
      }
    }
    try {
      const staticStatus = await getStaticVersionStatus(id);
      const sjsEnabled = isFeatureFlagEnabled(SJS_API);

      if (sjsEnabled && !staticStatus?.isStatic && staticStatus?.isInProgress) {
        await processPendingCommandsWithSJS(id);
        await retryFetchStaticVersion(id, statusRetryRef);
      }

      if (!staticStatus?.isStatic || staticStatus?.isCellReviewMigrationInProgress) {
        let presignedUrl;
        const forceGeneration =
          !sjsEnabled &&
          !staticStatus?.isInProgress &&
          !staticStatus?.staticFailure &&
          !staticStatus?.isCellReviewMigrationInProgress &&
          !staticStatus?.isExcelGenerated &&
          !staticStatus?.isSdcReconnected;
        if (
          !sjsEnabled &&
          (!staticStatus?.isInProgress || staticStatus?.staticFailure) &&
          !staticStatus?.isCellReviewMigrationInProgress
        ) {
          presignedUrl = await generateStaticVersion(id, forceGeneration);
        } else {
          presignedUrl = await getWorkbookModel(id);
        }

        if (!sjsEnabled) {
          await retryFetchStaticVersion(id, statusRetryRef);
        }

        return await fetchPresignedUrl(presignedUrl);
      } else {
        return await fetchPresignedUrl(await getWorkbookModel(id));
      }
    } catch (error) {
      throwStaticVersionError(error);
    }
  }
}

const throwStaticVersionError = error => {
  const newError = new Error();
  newError.details = [
    {
      code: 'STATIC_VERSION_ERROR',
      message: generalErrorMessage,
      error: error.message,
      errorDetail: staticGenerationError,
    },
  ];
  throw newError;
};

export async function retryFetchStaticVersion(id, statusRetryRef) {
  let isStatic = false;
  let staticFailure = false;
  let isCellReviewMigrationInProgress = true;
  let retries = 240;

  while ((!isStatic || isCellReviewMigrationInProgress) && --retries >= 0) {
    if (retries === 0) {
      staticGenerationError = 'Maximun retries';
      throw new Error('Static version generation failed. Reload the page');
    }

    if (statusRetryRef && !statusRetryRef.current) {
      staticGenerationError = 'Status request';
      throw new Error('Status request aborted');
    }

    if (staticFailure) {
      staticGenerationError = 'static generation failure';
      throw new Error('Static version generation has failed');
    }

    await new Promise(r => setTimeout(r, 1000));
    const staticStatus = await getStaticVersionStatus(id);
    if (staticStatus) {
      isStatic = staticStatus.isStatic;
      staticFailure = staticStatus.staticFailure;
      isCellReviewMigrationInProgress = staticStatus.isCellReviewMigrationInProgress;
    }
  }
}

async function getWorkbookModel(id, versionId) {
  const sjsEnabled = isFeatureFlagEnabled(SJS_API);

  let url = `${spreadsheetServiceHostName}/spreadsheet/files/${id}`;

  if (versionId) {
    url += `/version/${versionId}`;
  }

  if (sjsEnabled) {
    url += `?sjsFlag=true`;
  }

  const fetchOperation = async () => {
    return await wkpFetch.get(url);
  };

  return fetchAndRetryAsyncOperation(fetchOperation, url);
}
async function fetchPresignedUrl(presignedUrl) {
  const fetchOperation = async () => {
    return await fetch(presignedUrl);
  };
  return fetchAndRetryAsyncOperation(fetchOperation, presignedUrl, true);
}

export async function getWorkbookMetadata({ id, versionId }) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}${versionId ? '/' + versionId : ''}`;
  const metadataResponse = await wkpFetch.get(url);
  const res = metadataResponse.clone();

  if (!res.ok) {
    const errorText = await res.text();
    if (errorText) {
      throw Error(errorText);
    }
  } else {
    const { name, taxPeriod, readOnly, isGlobalTemplate } = await res.json();
    return {
      metadata: {
        name,
        taxPeriod,
      },
      readOnly,
      isGlobalTemplate,
    };
  }
}

export async function getLock(workpaperId) {
  const response = await wkpFetch.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);

  if (!response.ok) {
    if (response.status === 404) {
      const error = new Error(`Failed to get lock. Workpaper with ID ${workpaperId} not found (404)`);

      error.name = WORKPAPER_NOT_FOUND_ERROR;
      throw error;
    } else {
      throw new Error(`Failed to get lock`);
    }
  }

  return await response.json();
}

export async function setLock(workpaperId) {
  const response = await wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function deleteLock(workpaperId) {
  const response = await wkpFetch.remove(`${spreadsheetServiceHostName}/spreadsheet/workpapers/${workpaperId}/lock`);
  const data = await response.json();

  if (!response.ok) {
    if (response.status === 404) {
      return;
    }
    throw data.error;
  }

  return data;
}

export async function getTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.get(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );
  const data = await response.json();

  if (!response.ok) {
    throw Error('Error retrieving the export information.');
  }

  return data;
}

export async function createTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.post(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );

  if (!response.ok) {
    throw Error('Error when sending file to tax provision.');
  }
}

export async function deleteTaxProvisionExport(workpaperId) {
  const response = await wkpFetch.remove(
    `${workpapersNodeServiceHostName}/api/v1/workpapers/${workpaperId}/tax-provision-export`
  );

  if (!response.ok) {
    throw Error('An error has ocurred');
  }
}

export async function generateStaticVersion(workpaperId, forceGeneration = false) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/static-version/${workpaperId}/generate${
    forceGeneration ? `/${forceGeneration}` : ''
  }`;
  const fetchOperation = async () => {
    return await wkpFetch.get(url);
  };
  return fetchAndRetryAsyncOperation(fetchOperation, url);
}

export async function getStaticVersionStatus(id) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/static-version/${id}/status`;

  const fetchOperation = async () => {
    return await wkpFetch.get(url);
  };
  return fetchAndRetryAsyncOperation(fetchOperation, url);
}

export async function migrateCellReviews(id) {
  return await wkpFetch.post(`${spreadsheetServiceHostName}/spreadsheet/datareferences/cell-reviews/migrate/${id}`);
}

async function fetchAndRetryAsyncOperation(operation, url, isPresignData = false, maxRetries = 3, retryDelay = 1000) {
  let attempts = 0;
  let responseBody;
  let responseHeaders;
  while (attempts < maxRetries) {
    try {
      const operationResponse = await operation();
      const response = operationResponse.clone();
      responseBody = response.text();
      responseHeaders = Array.from(operationResponse.headers.entries());

      if (!response.ok) {
        const errorText = await response.text();
        if (errorText) {
          throw Error(errorText);
        }
      } else {
        const data = await operationResponse.json();
        if (isPresignData) {
          return {
            workbook: data,
            workbookLength: parseInt(operationResponse.headers.get('content-length')),
          };
        } else if (data.presignedUrl) {
          return data.presignedUrl;
        } else {
          return data;
        }
      }
    } catch (error) {
      if (attempts >= maxRetries - 1) {
        throw error;
      } else {
        attempts++;
        CustomLogger &&
          CustomLogger.pushLog(CustomLogger.operations.RETRY_OPEN, {
            url,
            responseBody,
            responseHeaders,
            error: JSON.stringify(error),
          });
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      }
    }
  }
}
