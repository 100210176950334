import { useRef, useState, useCallback } from 'react';
import { deleteLock, getLock, setLock } from './../apis';
import { getUser, logoutSubscribe } from '../../../_shared/auth';
import { useInterval } from './../../../global-components/Hooks/useInterval';
import { RENEW_LOCK_INTERVAL_MS } from '../Spreadsheet/shared/constants';
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from '../../../_shared/storage';

const lockValidationError = 'WKP0007';
const wkpsToReleaseLocalStorageKey = 'wkpsToRelease';

export default function useLockWorkpaper({ id }) {
  const [delayInterval] = useState(RENEW_LOCK_INTERVAL_MS);
  const [isRunningInterval, setIsRunningInterval] = useState(false);
  const renewLockInterval = useRef();
  const logoutUnsubscribe = useRef();

  const getWkpsToRelease = useCallback(() => {
    const existingWkpsToRelease = getDataFromLocalStorage(wkpsToReleaseLocalStorageKey) || '';
    return existingWkpsToRelease.split(',');
  }, []);

  const removeWkpFromToRelease = useCallback(
    wkpId => {
      setDataToLocalStorage(
        wkpsToReleaseLocalStorageKey,
        getWkpsToRelease().filter(id => wkpId !== id)
      );
    },
    [getWkpsToRelease]
  );

  const releaseUnlockedWorkpapers = useCallback(async () => {
    const user = await getUser();

    const checkAndDeleteLock = async wkpId => {
      if (!wkpId) return;

      const lockInfo = await getLock(wkpId);
      if (lockInfo?.userId === user.userId) {
        await deleteLock(wkpId);
      }
    };

    const existingWkpsToRelease = getWkpsToRelease();
    if (0 < existingWkpsToRelease?.length) {
      await Promise.all(existingWkpsToRelease.map(checkAndDeleteLock));
      removeDataFromLocalStorage(wkpsToReleaseLocalStorageKey);
    }
  }, [getWkpsToRelease]);

  const updateWkpsToRelease = useCallback(
    id => {
      if (!id) return;

      const existingWkpsToRelease = getWkpsToRelease();
      existingWkpsToRelease.push(id);

      setDataToLocalStorage(wkpsToReleaseLocalStorageKey, existingWkpsToRelease);
    },
    [getWkpsToRelease]
  );

  const lockCleanup = useCallback(async () => {
    setIsRunningInterval(false);
    logoutUnsubscribe.current?.unsubscribe();
    renewLockInterval.current = null;
    updateWkpsToRelease(id);
    await deleteLock(id);
  }, [id, updateWkpsToRelease]);

  const initLockRenew = () => {
    renewLock();
    removeWkpFromToRelease(id);
    logoutUnsubscribe.current = logoutSubscribe(lockCleanup);
    setIsRunningInterval(true);
  };

  const renewLock = async () => {
    try {
      await setLock(id);
    } catch ({ code }) {
      if (code === lockValidationError) {
        lockCleanup();
      }
    }
  };

  renewLockInterval.current = useInterval(renewLock, isRunningInterval ? delayInterval : null);

  const isLockInitialized = () => renewLockInterval.current !== null;

  return {
    initLockRenew,
    lockCleanup,
    isLockInitialized,
    releaseUnlockedWorkpapers,
  };
}
